require("dotenv").config();

const url_array = {
  //  'localhost:3000':"http://localhost:8000/api",
    'localhost:3001':"https://master.infrawheel.com/api",
   
  // 'localhost:3001':"https://denteasy.on-demand-app.com/admin/api",
  // "localhost:3000": "https://beta.infrawheel.com/admin/api",
  // 'localhost:3001': "https://beta.infrawheel.com/admin/api",
  // '192.168.0.56:3001': "https://beta.infrawheel.com/admin/api",
  // 'localhost:3000':"https://beta.infrawheel.com/admin/api",
  // 'localhost:3000':"https://nectacart.on-demand-app.com/admin/api",
  // 'localhost:3001':"https://beta.infrawheel.com/admin/api",
  // 'localhost:3001':"https://realestate.on-demand-app.com/refactoring/api",
  // 'realestate.on-demand-app.com':"https://realestate.on-demand-app.com/refactoring/api",
  // 'hanun.on-demand-app.com':"http://hanun.on-demand-app.com/admin/api",
  // 'shopkart.on-demand-app.com':"https://shopkart.on-demand-app.com/admin/api",
  // 'infrawheel.com':"https://infrawheel.com/admin/api",
  // 'beta.infrawheel.com':"https://master.infrawheel.com/api",
  // 'linosilk.com':"https://linosilk.com/admin/api",
  // 'nectacart.on-demand-app.com':"https://nectacart.on-demand-app.com/admin/api",
  // "betaweb.infrawheel.com": "https://betaadmin.infrawheel.com/api",
  // "iweb.infrawheel.com": "https://master.infrawheel.com/api",
  "infrawheel.com": "https://master.infrawheel.com/api",
};
const ApiConstants = {
  BASE_URL: url_array[window.location.host],
};

export default ApiConstants;

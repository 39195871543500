import React, { Component } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';

export default class Video extends Component {
    render() {
        const {image} = this.props;

        return (

            <video controls autoPlay={false}  className="_videos_products">
                <source src={image} type="video/mp4"/>
            </video>
        )
    }
}
import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {Link as ScrollLink} from 'react-scroll'
import Collapse from '../../../common/Collapse';
import moment from 'moment'
import {checkpincode_api} from "../../../../api/product";
import {checksinglevendo} from "../../../../utils/helper";
import ReactStars from "react-rating-stars-component";
import {toast} from "react-toastify";

import return_image from '../../../../assets/icone/return.png'
import Stars from "../../../common/stars";

class DetailsWithPrice extends Component {


    constructor(props) {
        super(props)
        this.state = {
            open: false,
            quantity: 1,
            stock: 'InStock',
            nav3: null,
            pincode: '',
            pincodemessage: '',
            is_like: 0,
            addedInCart: false,
        }
    }

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
    };

    setislike(value) {
        this.setState({is_like: value});
    }

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });
        setTimeout(() => {

        }, 300)
        if (this.props.item.product_val_type === 1 || this.props.item.product_val_type === 3) {
            this.checktype(1, this.props.item.product_min_quantity >= 1 ? this.props.item.product_min_quantity : 1)
        }
        if (this.props.item.product_val_type === 2) {
            this.checktype(2, this.props.item.product_min_wholesale_quantity >= 1 ? this.props.item.product_min_wholesale_quantity : 1)
        }

        setTimeout(()=> {
            if((this.state.quantity == this.props.item.product_min_quantity) || (this.state.quantity == this.props.item.product_min_wholesale_quantity)){
                this.setState({showPage: true})
            }else{
                this.setState({showPage: false})
            }
        }, 300)
    }

    minusQty = (max) => {
        if (this.state.quantity > 1) {
            this.setState({stock: 'In Stock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = (e, max) => {

        if (this.props.item.product_inventory_remain_stock >= this.state.quantity + 1) {

            this.setState({quantity: this.state.quantity + 1})


            if (this.state.quantity >= max) {
                toast.success("max quantity to buy this item is " + max + ".");
                this.setState({quantity: max})
            }

        } else {
            this.setState({stock: 'Out of Stock !'})

        }
    }

    changeQtynew(e, max) {
        // Clears the previously set timer.
        clearTimeout(this.typingTimeout);

        // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
        this.typingTimeout = setTimeout(this.callSearch, 475);


        if (e.target.value >= 1 && e.target.value <= max) {
            this.setState({quantity: parseInt(e.target.value)})
        } else if (e.target.value >= max + 1) {
            toast.success("max quantity to buy this item is " + max + ".");
            this.setState({quantity: max})
        } else {
            this.setState({quantity: ""})
        }

    }


    checktype = (type, qty) => {
        this.setState({type: type, quantity: parseInt(qty)})
    }

    handleScrollToElement(event) {
        const section = document.querySelector('#reviews_section_set');
        section.scrollIntoView({behavior: 'smooth', block: 'start'});
    }


    setdefaultqty(quantity) {
        this.setState({quantity: this.state.quantity - 1})
    }


    checkpincode = async (id, pincode) => {
        try {
            var apiParams = {
                product_id: id,
                zip_code: this.state.pincode,
            };
            let pincode = await checkpincode_api(apiParams);
            if (pincode.success) {
                this.setState({pincodemessage: pincode.message})
            } else {
                this.setState({pincodemessage: pincode.message})
            }
        } catch (error) {
            this.setState({addToCartApiCalled: false});
            console.log(error);
            toast.error(error.message);
        }
    }

    render() {
        const {
            symbol,
            item,
            addToCartClicked,
            BuynowClicked,
            addToWishlistClicked,
            set_product_option,
            product_option,
            set_product_option_onload,
            removeToWishlist,
            getproduct,
            
        } = this.props
            
        var colorsnav = {
            slidesToShow: 6,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,

        };
        var disable_option = [];
        var disable_option = [];
        var disable_option_list = [];
        var disable_option_list_array = new Set([66, 23, 63, 22, 25, 65, 64]);

        let RatingStars = []
        for (var i = 0; i < 5; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }

        const settotalprice = () => {

            if (this.state.quantity < item.product_min_wholesale_quantity && (item.product_val_type == 1 || item.product_val_type == 3) && (item.special_price == "" || item.special_price == 0 || item.special_price <= 0) && item.product_price != item.special_price) {
                return symbol + +(item.product_price * this.state.quantity).toFixed(2);
            } else if ((this.state.quantity >= item.product_min_wholesale_quantity || item.product_val_type == 2 || item.product_val_type == 3) && (item.product_val_type == 2 || item.product_val_type == 3) && isNaN(parseInt(item.wholesale_sp_price))) {
                return symbol + +(item.wholesale_price * this.state.quantity).toFixed(2);
            } else if (this.state.quantity < item.product_min_quantity && (item.product_val_type == 1 || item.product_val_type == 3) && (item.special_price == "" || item.special_price == 0 || item.special_price <= 0) && item.product_price != item.special_price) {
                return symbol + +(item.product_price * this.state.quantity).toFixed(2);
            } else {

                if(item.product_val_type == 1)
                {
                    if(item.special_price == "" || item.special_price == 0 || item.special_price <= 0)
                        return symbol + +(item.product_price * this.state.quantity).toFixed(2);
                    else
                        return symbol + +(item.special_price * this.state.quantity).toFixed(2);

                }

                if ((this.state.quantity >= item.product_min_wholesale_quantity || item.product_val_type == 2) && (item.product_val_type == 2 || item.product_val_type == 3) && item.wholesale_sp_price != "" && item.wholesale_sp_price != 0) {
                    return symbol + +(item.wholesale_sp_price * this.state.quantity).toFixed(2);
                } else if ((this.state.quantity >= item.product_min_wholesale_quantity || item.product_val_type == 2) && (item.product_val_type == 2 || item.product_val_type == 3)) {
                    return symbol + +(item.wholesale_price * this.state.quantity).toFixed(2);
                }
                return symbol + +(item.special_price * this.state.quantity).toFixed(2);
            }
        }

        const isactive = (i, n) => {

            if (i) {
                // disable_option[n].forEach(element =>  disable_option_list.push(element));
            }

        }
        const getactiveoption = (i) => {
            var data = [];

            disable_option_list_array.forEach(d => {

                var value = [];
                i.map(val => {

                    if (val) {
                        if (JSON.parse(val)[0] && d == JSON.parse(val)[0]) {
                            value.push(JSON.parse(val)[1])
                        }

                        if (JSON.parse(val)[1] && d == JSON.parse(val)[1]) {
                            value.push(JSON.parse(val)[0])
                        }
                    }

                })
                data[d] = value
            })

            disable_option = data

        }
        return (
            <div className='rtl-text'>
                <div className='product-right'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='font-size-text-30 details-title'>
                                {" "}
                                {item.product_name}
                            </h2>
                        </div>

                        <div className='col-sm-12 col-md-9 border-product radiobtn-price-wrp'>
                            <div className='d-flex'>
                                <div>
                                    {item.product_val_type == 1 ||
                                    item.product_val_type == 3 ? (
                                        <div className='form-check  form-check-inline detail-radio-button'>
                                            <input
                                                id='radio-1'
                                                name='radio'
                                                type='radio'
                                                defaultChecked={
                                                    ((item.product_val_type === 1 ||
                                                        item.product_val_type === 3))
                                                        ? true
                                                        : false
                                                }
                                                checked={ item.product_min_wholesale_quantity !== 0 ?
                                                    (this.state.quantity >= item.product_min_quantity && this.state.quantity < item.product_min_wholesale_quantity) ? true : false :
                                                    this.state.quantity >= item.product_min_quantity ? true : false
                                                }
                                                onClick={() => {
                                                    this.checktype(
                                                        1,
                                                        item.product_min_quantity >= 1
                                                            ? item.product_min_quantity
                                                            : 1
                                                    );
                                                }}
                                            />
                                            <label
                                                htmlFor='radio-1'
                                                className={
                                                    (item.product_val_type === 1 ||
                                                        item.product_val_type === 3)
                                                        ? "radio-label active_price"
                                                        : "radio-label notactive_price"
                                                }
                                            >
                                                <h3 className="product-price product-price-h3 d-inline font-size-text-28 fs-20">
                                                    {symbol}
                                                    {item.special_price != ""
                                                        ? item.special_price.toFixed(2)
                                                        : item.product_price.toFixed(2)}
                                                    <span className='-peace'>
                                  {item.unit_name ? "/ " + item.unit_name : ""}
                                </span>
                                                    {item.product_val_type === 2 &&
                                                    (item.product_val_type === 2 ||
                                                        item.product_val_type === 3) &&
                                                    item.special_price != "" &&
                                                    item.special_price != 0 ? (
                                                        <>
                                                            {item.wholesale_sp_price != "" &&
                                                            item.wholesale_sp_price !=
                                                            item.wholesale_price ? (
                                                                <p className='ml-2 text-left'>
                                                                    <del>
                                                                        {symbol}
                                                                        {item.wholesale_price.toFixed(2)}
                                                                    </del>
                                                                    <span>{parseFloat(100 - (item.wholesale_sp_price/item.wholesale_price*100)).toFixed(2)}% off</span>
                                                                </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item.special_price != 0 &&
                                                            item.special_price != item.product_price ? (
                                                                <p className='ml-2 text-left'>
                                                                    <del>
                                                                        {symbol}
                                                                        {item.product_price.toFixed(2)}
                                                                    </del>
                                                                    <span>{parseInt(parseFloat(100 - (item.special_price/item.product_price*100)).toFixed(2))}% off</span>
                                                                </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    )}
                                                </h3>
                                                <div className='font-size-text-15-Retail-Price text-left ml-4 '>
                                                    <b>Retail Price</b>
                                                </div>
                                                <div className='font-size-text-10-Retail-Price text-left ml-4'>
                                                    Inclusive of all taxes
                                                </div>
                                                {(item.product_min_quantity >= 1 &&
                                                    item.product_val_type == 3) ||
                                                item.product_val_type == 1 ? (
                                                    <div className='font-size-text-10-Retail-Price text-left ml-4'>
                                                        Minimum Quantity : {item.product_min_quantity}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div>
                                    {item.product_val_type == 2 ||
                                    item.product_val_type == 3 ? (
                                        <div
                                            className={
                                                item.product_val_type == 3
                                                    ? "form-check  float-sm-none   form-check-inline detail-radio-button float-md-right"
                                                    : "form-check  float-sm-none   form-check-inline detail-radio-button"
                                            }
                                        >
                                            <input
                                                id='radio-2'
                                                name='radio'
                                                type='radio'
                                                defaultChecked={
                                                    item.product_val_type === 2 ? true : false
                                                }
                                                checked={this.state.quantity >= item.product_min_wholesale_quantity ? true : false}
                                                onClick={() => {
                                                    this.checktype(
                                                        2,
                                                        item.product_min_wholesale_quantity >= 1
                                                            ? item.product_min_wholesale_quantity
                                                            : 1
                                                    );
                                                }}
                                            />
                                            <label
                                                htmlFor='radio-2'
                                                className={
                                                    (item.product_val_type === 1 ||
                                                        item.product_val_type === 3)
                                                        ? "radio-label active_price"
                                                        : "radio-label notactive_price"
                                                }
                                            >
                                                <h3 className='product-price product-price-h3 d-inline font-size-text-28 fs-20'>
                                                    {symbol}
                                                    {item.wholesale_sp_price != ""
                                                        ? item.wholesale_sp_price.toFixed(2)
                                                        : item.wholesale_price.toFixed(2)}
                                                    <span className='-peace'>
                                  {item.unit_name ? "/ " + item.unit_name : ""}
                                </span>
                                                    {(item.product_val_type === 2 ||
                                                        item.product_val_type === 3) &&
                                                    item.special_price != "" &&
                                                    item.special_price !== 0 ? (
                                                        <>
                                                            {item.wholesale_sp_price != "" &&
                                                            item.wholesale_sp_price !=
                                                            item.wholesale_price ? (
                                                                <p className='ml-2 text-left'>
                                                                    <del>
                                                                        {symbol}
                                                                        {item.wholesale_price.toFixed(2)}
                                                                    </del>
                                                                    <span>{parseInt(parseFloat(100 - (item.wholesale_sp_price/item.wholesale_price*100)).toFixed(2))}% off</span>
                                                                </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item.special_price != "" &&
                                                            item.special_price != item.product_price ? (
                                                                <p className='ml-2 text-left'>
                                                                    <del>
                                                                        {symbol}
                                                                        {item.product_price.toFixed(2)}
                                                                    </del>
                                                                    <span>{parseFloat(100 - (item.special_price/item.product_price*100)).toFixed(2)}% off</span>
                                                                </p>
                                                            ) : (
                                                                <>
                                                                    {item.product_val_type == 2 &&
                                                                    item.wholesale_sp_price != "" &&
                                                                    item.wholesale_sp_price !=
                                                                    item.wholesale_price ? (
                                                                        <p className='ml-2 text-left'>
                                                                            <del>
                                                                                {symbol}
                                                                                {item.wholesale_price.toFixed(2)}
                                                                            </del>
                                                                    <span>{parseFloat(100 - (item.wholesale_sp_price/item.wholesale_price*100)).toFixed(2)}% off</span>
                                                                        </p>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </h3>
                                                <div className='font-size-text-15-Retail-Price ml-4 text-left'>
                                                    <b>Wholesale Price</b>
                                                </div>
                                                <div className='font-size-text-10-Retail-Price ml-4 text-left'>
                                                    Inclusive of all taxes
                                                </div>
                                                {(item.product_min_wholesale_quantity >= 1 &&
                                                    item.product_val_type == 2) ||
                                                item.product_val_type == 3 ? (
                                                    <div className='font-size-text-10-Retail-Price ml-4 text-left'>
                                                        Minimum Quantity :{" "}
                                                        {item.product_min_wholesale_quantity}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-3 border-product productreview-wrp'>
                            <div className='w-100'>
                                {item.product_sku ? (
                                    <p className='font-size-text-10-Retail-Price text-center '>
                                        {"SKU:" + item.product_sku}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className='row'>
                                <div className='w-185px col-lg-12 col-md-6 col-sm-6 px-0'>
                                    <div className='d-flex flex-row bd-highlight mb-1 index-overallRatingContainer rounded '>
                                        <div className='index-overallRating px-2 py-2'>
                                            {item.check_rated_count &&
                                            item.check_rated_count.average_rating
                                                ? Math.round(item.check_rated_count.average_rating)
                                                : 0}
                                            <i
                                                class='fa fa-star fa-sm d-inline'
                                                style={{ color: "#ff7a0d" }}
                                                aria-hidden='true'
                                            ></i>
                                        </div>
                                        <div className='index-ratingsCount px-2  text-left'>
                                            {item.check_rated_count &&
                                            item.check_rated_count.count_rating
                                                ? item.check_rated_count.count_rating
                                                : 0}{" "}
                                            Reviews
                                            <p>
                                                <ScrollLink
                                                    activeClass='active'
                                                    to='Ratings-'
                                                    spy={true}
                                                    smooth={true}
                                                    offset={50}
                                                    duration={500}
                                                    className='theme_text_set_a font-size-text-10 text-center btn'
                                                    
                                                >
                                                    view all
                                                </ScrollLink>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-6 col-sm-6 w-200px pl-0 mt-2 px-0'>
                                    <div className=' border-0'>
                                        <div id='socialRow'>
                                            <div className='product-icon'>
                                                <button className='wishlist-btn' onClick={()=>{
                                                  {  item.is_in_wishlist === 0 ?   
                                                    addToWishlistClicked(item) :
                                                    removeToWishlist(item) }
                                                    getproduct();
                                                }}> 

                                                    <i className={ `fa fa-heart ${item.is_in_wishlist === 0 ? 'unactive-wishlist-icon' : 'active-wishlist-icon'}`} aria-hidden='true'></i>
                                                        <span className={item.is_in_wishlist === 0 ? 'Add-to-Whishlist' : 'remove-to-Whishlist'}>
                                                        {item.is_in_wishlist === 0 ? 'Add To Wishlist' : 'Remove To Wishlist'}
                                                        </span>
                                                   </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='category-d-flex' />
                        </div>

                        <div className='col-sm-12 productsize-wrp'>
                            <form>
                                <div
                                    className='form-row'
                                    disabled={getactiveoption(item.remove_option_list)}
                                >
                                    {item.product_option && item.product_option.length > 0
                                        ? item.product_option.map((data, index) => (
                                            <>
                                                {data.type == "checkbox" ? (
                                                    <div className='form-group col-6 col-md-6'>
                                                        <label
                                                            className='product-detail-options-title set_color_dark_1'
                                                            htmlFor={"exampleFormControlSelect" + index}
                                                        >
                                                            {data.name}
                                                        </label>
                                                        <div
                                                            className=''
                                                            id={"exampleFormControlSelect" + index}
                                                        >
                                                            {data.product_option_value.map(
                                                                (size, i) => {
                                                                    return (
                                                                        <div className='form-check form-check-inline'>
                                                                            <input
                                                                                className='form-check-input'
                                                                                type='radio'
                                                                                disabled={JSON.stringify(
                                                                                    disable_option_list
                                                                                ).includes(size.option_value_id)}
                                                                                readOnly={isactive(
                                                                                    item &&
                                                                                    item.options_json &&
                                                                                    item.options_json
                                                                                        .toString()
                                                                                        .includes(
                                                                                            size.option_value_id
                                                                                        ),
                                                                                    size.option_value_id
                                                                                )}
                                                                                defaultChecked={
                                                                                    item &&
                                                                                    item.options_json &&
                                                                                    item.options_json
                                                                                        .toString()
                                                                                        .includes(
                                                                                            size.option_value_id
                                                                                        )
                                                                                }
                                                                                value={size.option_value_id}
                                                                                name={"flex" + index}
                                                                                id={"flexRadioDefault1" + i}
                                                                                onClick={(e) => {
                                                                                    if (
                                                                                        !JSON.stringify(
                                                                                            disable_option_list
                                                                                        ).includes(
                                                                                            size.option_value_id
                                                                                        )
                                                                                    ) {
                                                                                        // alert('sdsadas')
                                                                                        // return false;
                                                                                    }

                                                                                    set_product_option(
                                                                                        size.option_value_id,
                                                                                        index,
                                                                                        item.child_id || item.id
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <label
                                                                                className='form-check-label detailpage_option_title'
                                                                                htmlFor={"flexRadioDefault1" + i}
                                                                            >
                                                                                {size.option_name}
                                                                            </label>
                                                                            {/*{JSON.stringify(JSON.stringify(disable_option_list).includes(size.option_value_id))}*/}
                                                                            {/*{JSON.stringify(disable_option_list)}*/}
                                                                            {/*{JSON.stringify(size.option_value_id)}*/}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='form-group col-6 col-md-6'>
                                                        <label
                                                            className='product-detail-options-title set_color_dark_1'
                                                            htmlFor={"exampleFormControlSelect" + index}
                                                        >
                                                            {data.name}
                                                        </label>
                                                        <select
                                                            className='form-control'
                                                            id={"exampleFormControlSelect" + index}
                                                        >
                                                            {data.product_option_value.map(
                                                                (size, i) => {
                                                                    return (
                                                                        <option
                                                                            data-max={index}
                                                                            onClick={(e) => {
                                                                                set_product_option(
                                                                                    size.option_value_id,
                                                                                    index
                                                                                );
                                                                            }}
                                                                            selected={
                                                                                item &&
                                                                                item.options_json &&
                                                                                item.options_json
                                                                                    .toString()
                                                                                    .includes(size.option_value_id)
                                                                            }
                                                                            value={size.option_value_id}
                                                                        >
                                                                            {size.option_name}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                )}
                                            </>
                                        ))
                                        : ""}
                                </div>
                            </form>
                            <hr />
                        </div>
                        <div className='col-sm-12 col-md-6 productquantity-wrp'>
                            <form>
                                <div className='form'>
                                    <div className='form-group '>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <label
                                                    htmlFor='inputEmail4d'
                                                    className='float-left'
                                                >
                                                    <b className='set_color_dark_1'>Quantity</b>
                                                </label>
                                            </div>
                                            <div className='col-sm-12'>
                                                <div className=''>
                                                    <div
                                                        className='input-group m-automobile float-left'
                                                        style={{ width: "50%" }}
                                                    >
                                  <span className='input-group-prepend bor'>
                                    <button
                                        type='button'
                                        className='btn quantity-left-minus '
                                        onClick={this.minusQty}
                                        disabled={
                                            ((item.product_val_type == 1 || item.product_val_type == 3) && this.state.quantity == item.product_min_quantity) ||
                                            ((item.product_val_type == 2) && this.state.quantity == item.product_min_wholesale_quantity) ? true : false}
                                        data-type='minus'
                                        data-field=''
                                    >
                                      <i className='fa fa-angle-left'></i>
                                    </button>
                                  </span>
                                                        <input
                                                            type='text'
                                                            readOnly={false}
                                                            name='quantity'
                                                            value={this.state.quantity}
                                                            // onBlur={(e)=>{this.changeQty(e,item.max_quantity)}}
                                                            // onChange={(e)=>{this.changeQty(e,item.max_quantity)}}
                                                            onChange={(e) => {
                                                                this.changeQtynew(e, item.max_quantity);
                                                            }}
                                                            className='form-control input-number bg-white text-center'
                                                        />
                                                        <span className='input-group-prepend bor'>
                                    <button
                                        type='button'
                                        className='btn quantity-right-plus'
                                        onClick={(e) => {
                                            this.plusQty(e, item.max_quantity);
                                        }}
                                        data-type='plus'
                                        data-field=''
                                    >
                                      <i className='fa fa-angle-right'></i>
                                    </button>
                                  </span>
                                                    </div>

                                                    <div className='price_in_detail_page'>
                                  <span className='product-details-total-span set_color_dark_1'>
                                    Total
                                  </span>
                                                        <br />
                                                        <span className='total-price'>
                                    {" "}
                                                            {settotalprice()}
                                  </span>
                                                    </div>
                                                    <div className='col-12 p-0'>
                                                        {this.props.item
                                                            .product_inventory_remain_stock == 0 ||
                                                        ((this.props.item.product_val_type == 1 ||
                                                            this.props.item.product_val_type == 3) &&
                                                            this.props.item
                                                                .product_inventory_remain_stock <
                                                            this.props.item.product_min_quantity) ||
                                                        (this.props.item.product_val_type == 2 &&
                                                            this.props.item
                                                                .product_inventory_remain_stock <
                                                            this.props.item
                                                                .product_min_wholesale_quantity) ? (
                                                            <>
                                                                <p className='ReturnableProduct  float-left w-100 text-left'>
                                                                    Out of Stock!
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className='ReturnableProduct2  float-left w-100 text-left'>
                                                                    {this.state.stock}
                                                                </p>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className='col-12 p-0'>
                                                        {item.product_min_quantity >= 1 &&
                                                        (item.product_val_type == 1 ||
                                                            item.product_val_type == 3) &&
                                                        this.state.quantity < item.product_min_wholesale_quantity ? (
                                                            <div className='w-100 font-size-text-10-Retail-Price'>
                                                                Minimum Retail Quantity :{" "}
                                                                {item.product_min_quantity}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {item.product_min_wholesale_quantity >= 1 &&
                                                        (item.product_val_type == 2 ||
                                                            item.product_val_type == 3) &&
                                                        this.state.quantity >= item.product_min_wholesale_quantity ? (
                                                            <div className='w-100 font-size-text-10-Retail-Price'>
                                                                Minimum Wholesale Quantity :{" "}
                                                                {item.product_min_wholesale_quantity}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    {item.free_delivery_quantity >= 1 ? (
                                                        <p className='ReturnableProduct3'>
                                                            Note : minimum {item.free_delivery_quantity}{" "}
                                                            quantity for free delivery!{" "}
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-sm-12 col-md-6 deliveryoption-wrp'>
                            <form>
                                <div className='form'>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <label
                                                    htmlFor='inputEmail4d'
                                                    className='float-left'
                                                >
                                                    <b className='set_color_dark_1'>Check Delivery</b>
                                                </label>
                                            </div>
                                            <div className='col-md-12'>
                                                <div
                                                    className='input-group'
                                                    style={{ width: "auto" }}
                                                >
                                                    <input
                                                        type='text'
                                                        name='checkpincode'
                                                        placeholder='Enter Pincode'
                                                        style={{ borderRadius: "5px" }}
                                                        defaultValue={this.state.pincode}
                                                        onKeyUp={(evt) => {
                                                            this.setState({ pincode: evt.target.value });
                                                        }}
                                                        className='form-control input-number bg-white '
                                                    />
                                                    <span className='input-group-prepend bor'>
                                  <button
                                      type='button'
                                      className='btn btn-solid2  btn-block'
                                      style={{ borderRadius: "5px" }}
                                      data-field=''
                                      onClick={() => {
                                          this.checkpincode(
                                              item.id,
                                              this.state.pincode
                                          );
                                      }}
                                  >
                                    Check
                                  </button>
                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                {this.state.pincodemessage ? (
                                                    <p className='Get-it-on-by-25-sept float-left p-1'>
                                                        {this.state.pincodemessage}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className='col-12'>
                                                <p
                                                    style={{ color: "#2472F0" }}
                                                    className='Get-it-on-by-25-sept float-left p-1'
                                                >
                                {/* <span className='font-weight-bold text-danger'>
                                {item.return_day.includes('0days')  ? "Non-Returnable Product" : item.return_day  + `Returnable Product ${item.return_day}`} 
                                </span> */}
                                        <span className='font-weight-bold text-danger'>
                                            {
                                                console.log(item.return_day ,"item.return_day")
                                            }
                                            {
                                               (item.return_day == "0days" || item.return_day == "0") ? "Non-Returnable Product" : `Returnable Product in ${item.return_day} Day(s)`
                                            }
                                        </span>
                                                  <br/>
                                                    {                                                  
                                                        item.delivery_time  === "0days" ? 
                                                        <></> :
                                                       <>
                                                        Get it on by {" "} 
                                                       <b>{moment().add(parseInt(item.delivery_time), "d").format("D MMMM Y")}</b>
                                                       </>
                                                    }
                                                    <br />
                                                  
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row social-d-block-row'>
                                            <div className='col-md-6 col-sm-12 social-d-block'>
                                                <div
                                                    className='border-product d-block border-set rounded'
                                                    id='socialRow'
                                                >
                                                    <h6 className='product-title d-inline'>
                                                        Share it on social :{" "}
                                                    </h6>
                                                    <div className='product-icon d-inline'>
                                                        <ul className='product-social d-inline'>
                                                            <li>
                                                                <a
                                                                    href='https://www.facebook.com/'
                                                                    target='_blank'
                                                                >
                                                                    <i className='fa fa-facebook'></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href='https://plus.google.com/discover'
                                                                    target='_blank'
                                                                >
                                                                    <i className='fa fa-google-plus'></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href='https://twitter.com/'
                                                                    target='_blank'
                                                                >
                                                                    <i className='fa fa-twitter'></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href='https://www.instagram.com/'
                                                                    target='_blank'
                                                                >
                                                                    <i className='fa fa-instagram'></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-sm-12 social-d-block'>
                                                <div
                                                    className='border-product row border-set rounded my-auto'
                                                    id='socialRow'
                                                >
                                                    <div className='col-12'>
                                                        <h6 className='product-title d-inline ml-2'>
                                                            <img
                                                                src={return_image}
                                                                width='15px'
                                                                height='15px'
                                                                alt=''
                                                            />
                                                            Returns
                                                        </h6>
                                                    </div>
                                                    <div className='col-12'>
                                                        <h5>{item.return_day} day</h5>
                                                    </div>
                                                    {/* <ul className="product-social d-inline">
                                                          <p className="ReturnableProduct float-left p-1">{item.return_day} Returnable Product</p>
                                                      </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-sm-12 col-md-6 leftAvail'>
                            <div className='product-button pb-2'>
                                {this.props.item.product_inventory_remain_stock == 0 ||
                                ((this.props.item.product_val_type == 1 ||
                                    this.props.item.product_val_type == 3) &&
                                    this.props.item.product_inventory_remain_stock <
                                    this.props.item.product_min_quantity) ||
                                (this.props.item.product_val_type == 2 &&
                                    this.props.item.product_inventory_remain_stock <
                                    this.props.item.product_min_wholesale_quantity) ? (
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/checkout`}
                                        className='btn btn-solid btn-lg btn-block'
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        Currently not available
                                    </Link>
                                ) : (
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/checkout`}
                                        className='btn btn-solid btn-lg btn-block'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            addToCartClicked(item, this.state.quantity);
                                            setTimeout(() => {
                                                window.location.href = "/checkout";
                                            }, 3000);
                                        }}
                                    >
                                        {item.is_pre_order === 1 &&
                                        moment().isAfter(item.pre_start_delivery_date) &&
                                        moment().isBefore(item.date_available)
                                            ? "Pre Order"
                                            : "Buy it now"}
                                    </Link>
                                )}

                                <p>
                                    {item.is_pre_order === 1 &&
                                    moment().isBefore(item.pre_start_delivery_date)
                                        ? "Product live at " + item.pre_start_delivery_date
                                        : ""}
                                </p>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 rightAvail'>
                            <div className='product-button  '>
                                {this.props.item.product_inventory_remain_stock == 0 ||
                                ((this.props.item.product_val_type == 1 ||
                                    this.props.item.product_val_type == 3) &&
                                    this.props.item.product_inventory_remain_stock <
                                    this.props.item.product_min_quantity) ||
                                (this.props.item.product_val_type == 2 &&
                                    this.props.item.product_inventory_remain_stock <
                                    this.props.item.product_min_wholesale_quantity) ? (
                                    <a
                                        className='btn btn-solid btn-solids btn-lg btn-block'
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        Currently not available
                                    </a>
                                ) : (
                                this.state.addedInCart ?
                                <div className='btn btn-solid btn-solids btn-lg btn-block'>add to cart</div> :
                                   ( <a
                                        className='btn btn-solid btn-solids btn-lg btn-block'
                                        onClick={() => {
                                            addToCartClicked(item, this.state.quantity)
                                            this.setState({addedInCart: true})
                                        }}
                                    >
                                        {item.is_pre_order === 1 &&
                                        moment().isBefore(item.pre_start_delivery_date)
                                            ? "Pre Order"
                                            : "add to cart"}{" "}
                                    </a>)
                                )}

                                <p>
                                    {item.is_pre_order === 1 &&
                                    moment().isBefore(item.pre_start_delivery_date)
                                        ? "Product live at " + item.pre_start_delivery_date
                                        : ""}
                                </p>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 my-3 productsellerinfo-wrp '>
                            {checksinglevendo() == true ? (
                                <div className='col-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <h6
                                                className='font-weight-bold text-start'
                                                style={{ color: "#000" }}
                                            >
                                                Seller Info. {JSON.stringify(checksinglevendo)}
                                            </h6>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Seller Name
                                            </h6>
                                            <p className='text-start'>
                                                {item.seller_details.seller_name}
                                            </p>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                City
                                            </h6>
                                            <p className='text-start'>
                                                {item.seller_details.store_city}
                                            </p>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Reviews
                                            </h6>
                                            <Stars total={5} active={item.check_rated_count.average_rating || 0}></Stars>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}

                            <div className='col-12 mt-2'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <h6
                                            className='font-weight-bold text-start'
                                            style={{ color: "#000" }}
                                        >
                                            Product Details
                                        </h6>
                                    </div>
                                    <div className='col-lg-12 col-md-12'>
                                        <div
                                            className='text-start'
                                            dangerouslySetInnerHTML={{
                                                __html: item.product_description,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <h6
                                            className='font-weight-bold my-2 text-start'
                                            style={{ color: "#000" }}
                                        >
                                            Specification
                                        </h6>
                                    </div>

                                    {item.model ? (
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Model
                                            </h6>
                                            <p className='text-start'>{item.model}</p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {item.product_sku ? (
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                SKU
                                            </h6>
                                            <p className='text-start'>{item.product_sku}</p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {item.product_location ? (
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Product location
                                            </h6>
                                            <p className='text-start'>{item.product_location}</p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {item.brand_name ? (
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Brand
                                            </h6>
                                            <p className='text-start'>{item.brand_name}</p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {item.product_length ? (
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Length
                                            </h6>
                                            <p className='text-start'>{item.product_length} {" "} {item.dimension_name} </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {item.product_width ? (
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Width
                                            </h6>
                                            <p className='text-start'>{item.product_width} {" "} {item.dimension_name}</p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {item.product_height ? (
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Height
                                            </h6>
                                            <p className='text-start'>{item.product_height} {" "} {item.dimension_name}</p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {item.product_weight ? (
                                        <div className='col-md-4 col-6'>
                                            <h6 className='theme_text_set font-weight-bold text-start'>
                                                Weight
                                            </h6>
                                            <p className='text-start'>{item.product_weight}{" "} {item.weight_name} </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {item.product_attribute &&
                                    item.product_attribute.length > 0
                                        ? item.product_attribute.map((val, i) => {
                                            return (
                                                <div className='col-md-4 col-6'>
                                                    <h6 className='theme_text_set font-weight-bold text-start'>
                                                        {val.attribute_name}
                                                    </h6>
                                                    <p className='text-start'>
                                                        {" "}
                                                        {val.product_attribute_text}
                                                    </p>
                                                </div>
                                            );
                                        })
                                        : ""}
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 product-details-policy'>
                                        {item.product_policy && item.product_policy.length > 0
                                            ? item.product_policy.map((data, index) => (
                                                <Collapse ListTopic={data.policy_name}>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.policy_description,
                                                        }}
                                                    />
                                                </Collapse>
                                            ))
                                            : ""}
                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailsWithPrice;

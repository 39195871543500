import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {vendorRegister_Api} from "../../api/auth";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { getHomeSidebarCategories_api } from "../../api/home"
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import AddressAutoComplete from "./PlacesAutocomplete"
import countryData from "./countries.json";

const SellerRegister = (props) => {

    // useEffect(()=> {
    //     // navigator.geolocation.getCurrentPosition(function(position) {
    //     //     console.log("Latitude is :", position.coords.latitude);
    //     //     console.log("Longitude is :", position.coords.longitude);
    //     //   });
    //       setInputValues({ ...inputValues, device_token: "web", device_type: "web"})
    // }, [inputValues])

    const [inputValues, setInputValues] = useState({});
    const [selectedCategoryOption, setSelectedCategoryOption] = useState([])
    const [selectedProductsFrom, setSelectedProductsFrom] = useState([])
    const [loader, setLoader] = useState(false)
    const [categories, setCategories] = useState([])

    const handleOnChange = event => {
      const { name, value, selectedOptions, files, checked } = event.target;
      
      switch(name) {
        case "personal_id_proof" :
        case "profile_image" :
        case "personal_signature" :
        // case "store_address_proof" :
        case "personal_pancard" :
        case "store_gst_proof" :
          setInputValues({ ...inputValues, [name+"_preview"]: files.length > 0 ? URL.createObjectURL(files[0]) : "", [name]: files[0]})
          break;
        case "name":
          setInputValues({ ...inputValues, [name]: value, store_seller_name: value, device_token: "web", device_type: "web" });
          break;
        case "store_is_gst" :
          setInputValues({ ...inputValues, [name]: JSON.parse(value)})
          break;
        case "gst_no" :
          if (value.length < 16) {
            setInputValues({ ...inputValues, [name]: value.toUpperCase()})
          }
          break;
        case "store_pancard_no" :
          if (value.length < 11) {
            setInputValues({ ...inputValues, [name]: value.toUpperCase()})
          }
          break;

        default:
          setInputValues({ ...inputValues, [name]: value });
          break;
      }
    };

    const handleMultipleSelectCategory = event => {
      setSelectedCategoryOption(event)
    }
    const handleMultipleSelectProducts = event => {
      setSelectedProductsFrom(event)
    }

    useEffect(()=> {
      setInputValues({ ...inputValues, store_product_from: selectedProductsFrom.map(opt => opt.value).toString()})
    }, [selectedProductsFrom])

    useEffect(()=> {
      if(selectedCategoryOption.length > 0){
        setInputValues({ ...inputValues, store_category: selectedCategoryOption.map(opt => opt.id).toString()})
      }
    }, [selectedCategoryOption])

    
    const registerVendor = async () => {
      if(inputValues.name == "" || inputValues.name == undefined){
        toast.error("First Name Required")
      }
      else if (inputValues.last_name == "" || inputValues.last_name == undefined) {
        toast.error("Last Name Required")
      }
      else if (inputValues.email == "" || inputValues.email == undefined) {
        toast.error("Email Required")
      }
      else if (inputValues.mobile_no == "" || inputValues.mobile_no == undefined) {
        toast.error("Mobile Number Required")
      }
      else if (inputValues.country_code == "" || inputValues.country_code == undefined) {
        toast.error("Country Code Required")
      }
      else if (inputValues.password == "" || inputValues.password == undefined) {
        toast.error("Password Required")
      }
      else if (inputValues.personal_id_proof == null || inputValues.personal_id_proof == undefined) {
        toast.error("Personal Id Required")
      }
      else if (inputValues.store_name == "" || inputValues.store_name == undefined) {
        toast.error("Store Name Required")
      }
      else if (inputValues.store_mobile_no == "" || inputValues.store_mobile_no == undefined) {
        toast.error("Store Mobile Number Required")
      }
      // else if (inputValues.store_phone_no == "" || inputValues.store_phone_no == undefined) {
      //   toast.error("Store Phone Number Required")
      // }
      else if (inputValues.store_email == "" || inputValues.store_email == undefined) {
        toast.error("Store Email Required")
      }
      else if (inputValues.store_pancard_no == "" || inputValues.store_pancard_no == undefined) {
        toast.error("Store PAN Number Required")
      }
      else if (inputValues.store_address == "" || inputValues.store_address == undefined) {
        toast.error("Store Address Required")
      }
      else if (inputValues.store_colony == "" || inputValues.store_colony == undefined) {
        toast.error("Store Block/Apt/Office Required")
      }
      else if (inputValues.store_landmark == "" || inputValues.store_landmark == undefined) {
        toast.error("Store Landmark Required")
      }
      else if (inputValues.store_flat_no == "" || inputValues.store_flat_no == undefined) {
        toast.error("Store Street Required")
      }
      else if (inputValues.store_city == "" || inputValues.store_city == undefined) {
        toast.error("Store City Required")
      }
      else if (inputValues.store_state == "" || inputValues.store_state == undefined) {
        toast.error("Store State Required")
      }
      else if (inputValues.store_country == "" || inputValues.store_country == undefined) {
        toast.error("Store Country Required")
      }
      else if (inputValues.what_you_are == "" || inputValues.what_you_are == undefined) {
        toast.error("Select What are you")
      }
      else if (inputValues.store_product_from == "" || inputValues.store_product_from == undefined) {
        toast.error("Select Where do you get products from")
      }
      else if (inputValues.store_annual_turnover == "" || inputValues.store_annual_turnover == undefined) {
        toast.error("Select Annual Turnover")
      }
      else if (inputValues.store_product_sell == "" || inputValues.store_product_sell == undefined) {
        toast.error("Select How many Products do you sell")
      }
      else if (inputValues.store_is_gst == null || inputValues.store_is_gst == undefined) {
        toast.error("Select Your GST Status")
      }
      else if ((inputValues.store_is_gst && inputValues.store_is_gst == true) && (inputValues.gst_no == "" || inputValues.gst_no == undefined)) {
        toast.error("Enter Your GST Number")
      }
      else if (inputValues.store_category == "" || inputValues.store_category == undefined) {
        toast.error("Select Your Store Categories")
      }
      else if (inputValues.personal_pancard == null || inputValues.personal_pancard == undefined) {
        toast.error("Upload PAN Card")
      }
      else if (inputValues.store_gst_proof == null || inputValues.store_gst_proof == undefined) {
        toast.error("Upload Business Proof")
      }
      else if (inputValues.personal_signature == null || inputValues.personal_signature == undefined) {
        toast.error("Upload Your Signature")
      }
      else if (inputValues.profile_image == null || inputValues.profile_image == undefined) {
        toast.error("Upload Your Logo")
      }
      else {
        try {
          let formData = new FormData()
          Object.keys(inputValues).forEach(key => {
            formData.append(key, inputValues[key]);
          })

          // this.setState({ loaderForAddress: true });
          setLoader(true)
          const vendorRegister = await vendorRegister_Api(formData);
          setLoader(false)
          toast.success(vendorRegister.message);
          // window.location.href = "/"
        } catch (error) {
          console.log(error);
          toast.error(error.message);
          setLoader(false)
          // this.setState({ loaderForAddress: false });
        }
      }
    };

    const Option = (props) => {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            <label>{props.label}</label>
          </components.Option>
        </div>
      );
    };

    const getCategories_API = async() => {
      const category = await getHomeSidebarCategories_api()
      setCategories(category.data)
    }

    useEffect(()=> {
      getCategories_API()
    },[])

    const newCategoryOptions = categories.map((value) => ({
      ["value"]: value.category_name,
      ["label"]: value.category_name,
      ["id"]: value.id
    }));
    // console.info("NewList Category", newCategoryOptions && newCategoryOptions)
    const categoryOptions = [
      // { value: "Cement", label: "Cement" },
      // { value: "Bricks", label: "Bricks" },
      // { value: "Sand", label: "Sand" },
      // { value: "Electrical", label: "Electrical" },
      // { value: "Plumbing", label: "Plumbing" },
      // { value: "Wooden Products", label: "Wooden Products" },
      // { value: "Paints", label: "Paints" },
      // { value: "Paint Finishes", label: "Paint Finishes" },
      // { value: "Doors & Windows", label: "Doors & Windows" },
    ];

    const getProductsOptions = [
      { value: "1", label: "I manufacture them" },
      { value: "2", label: "I resell product that I buy" },
      { value: "3", label: "I import them" },
    ];

  return (
    <>
    <Helmet>
      <title>Sell On Infrawheel | </title>
      <meta name="description" content="" />
    </Helmet>
    <div className="hide-breadcrumbs">
      <Breadcrumb title={"Sell On Infrawheel"} />
    </div>
      <section className='section-b-space reselForm'>
        <div className='container'>
          <div className='row d-flex h-100 justCent' style={{ paddingBottom: "20px" }}>
              <div className="col-12">
                <h3 className="text-uppercase" style={{color: "#222222"}}>Sell On Infrawheel</h3>
              </div>
                <div class='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                  <div className="row pb-5">
                    <div className="col-12">
                      <div className="outerBox" style={{borderRadius: "8px"}}>
                        <div className="container">
                          <div className="p-2">
                            <img src={"https://beta.infrawheel.com/admin/public/stores/f16ac21d2ae3e368cc63cac08ef93499.png"} width="175" height={"60"} className="img-fluid" />
                          </div>
                          <h5 className="font-size-text-14" style={{color: "#222222"}}>{"Sell On InfraWheel is simple & easy."}</h5>
                          <span className="d-block font-weight-bold pt-2 pb-2"> {"Simple 5 Steps to become seller on Infrawheel :"} </span>
                          <span className="d-block">1. Fill up your Personal Information.</span>
                          <span className="d-block">2. Fill up your Main Store/branch/warehouse details.</span>
                          <span className="d-block">3. Fill up your Address.</span>
                          <span className="d-block">4. Fill up your Business Information.</span>
                          <span className="d-block">5. Upload Required Proofs.</span>
                          <span className="d-block text-danger pt-2 pb-2">{"Note: all the fields are mendatory & upload required documents to continue."}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                    <h4 style={{fontWeight:"bolder", color: "#222222"}} >Personal Information</h4>
                    <div class='form-group'>
                      <label for='name' class='col-form-label required'>
                        First Name
                      </label>
                      <input
                        name='name'
                        type='text'
                        class='form-control'
                        id='name'
                        maxLength='50'
                        placeholder='First Name'
                        onChange={(e)=> handleOnChange(e)}
                      />
                    </div>
                    {/* <!--end form-group--> */}
                    <div class='form-group'>
                      <label for='last name' class='col-form-label required'>
                        Last Name
                      </label>
                      <input
                        name='last_name'
                        type='text'
                        className='form-control'
                        id='last name'
                        placeholder='Last Name'
                        onChange={(e)=> handleOnChange(e)}
                      />
                    </div>

                    <div class='form-group'>
                      <label for='email' class='col-form-label required'>
                        Email
                      </label>
                      <input
                        name='email'
                        type='email'
                        className='form-control'
                        id='email'
                        placeholder='Your Email'
                        onChange={(e)=> handleOnChange(e)}
                      />
                    </div>

                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-group">
                        <label for='mobile_no' class='col-form-label required'>
                          Mobile Number
                        </label>
                          <select name="country_code" className="form-control" onChange={(e)=> handleOnChange(e)}>
                            <option selected value={""}>Select Code</option>
                            {countryData.map((item, i) => {
                              return (
                                  <option key={i} value={item.dial_code}>
                                      {item.name}&nbsp;+{item.dial_code}
                                  </option>
                              );
                          })}
                          </select>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div class='form-group'>
                        <label className="col-form-label">&nbsp;</label>
                          <input
                            name='mobile_no'
                            type='tel'
                            className='form-control'
                            id='mobile_no'
                            maxLength={10}
                            placeholder='Your Mobile Number'
                            onChange={(e)=> handleOnChange(e)}
                          />
                      </div>
                    </div>
                  </div>

                    <div class='form-group'>
                      <label for='password' class='col-form-label required'>
                        Password
                      </label>
                      <input
                        name='password'
                        type='password'
                        className='form-control'
                        id='pass'
                        placeholder='Password'
                        onChange={(e)=> handleOnChange(e)}
                      />
                    </div>

                    <div className="form-group">
                      <label className="col-form-label required">
                        Personal ID proof
                      </label>
                      <div className="d-flex">
                        <div className="sellerRegPersonalImg">
                          <img src={inputValues.personal_id_proof_preview ? inputValues.personal_id_proof_preview : ""} className="img-fluid"  width={"100"} height={"60"} />
                        </div>
                        <div>
                          <small className="mb-3 font-weight-bolde" >Upload your Proofs in PDF, JPEG or PNG Format</small>
                          <input type="file" onChange={(e) => handleOnChange(e)} name="personal_id_proof" className='form-control' accept="image/*,.pdf" />
                        </div>
                      </div>
                      

                    </div>
                    {/* <!--end form-group--> */}
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <h4 style={{fontWeight:"bolder", color: "#222222"}}>Main Store/branch/warehouse details</h4>
                    <div className='form-group'>
                          <label className='col-form-label required'>
                            Store Name
                          </label>
                          <input
                              name='store_name'
                              type='text'
                              className='form-control'
                              placeholder='Enter Store Name'
                              onChange={(e) => handleOnChange(e)}
                            />
                    </div>
                    <div className="form-group">
                        <label className='col-form-label required'>
                            Store Mobile Number
                        </label>
                        <input
                          name='store_mobile_no'
                          type='tel'
                          maxLength={10}
                          className='form-control'
                          placeholder='Enter Store Mobile Number'
                          onChange={(e)=> handleOnChange(e)}
                        />
                    </div>
                    <div className="form-group">
                        <label className='col-form-label'>
                            Store Phone Number
                        </label>
                        <input
                          name='store_phone_no'
                          type='tel'
                          className='form-control'
                          placeholder='Enter Store Phone Number'
                          onChange={(e)=> handleOnChange(e)}
                        />
                    </div>
                    <div className="form-group">
                        <label className='col-form-label required'>
                            Store Email
                        </label>
                        <input
                          name='store_email'
                          type='email'
                          className='form-control'
                          placeholder='Enter Store Email'
                          onChange={(e)=> handleOnChange(e)}
                        />
                    </div>

                    <div className="form-group">
                      <label className="col-form-label required">
                        Store PAN Number
                      </label>
                      <input type={"text"} className="form-control" 
                      name="store_pancard_no" 
                      maxLength={10}
                      value={inputValues.store_pancard_no && inputValues.store_pancard_no} 
                      placeholder="Enter PAN Card Number" onChange={(e)=> handleOnChange(e)} />
                    </div>
                    <div className="form-group">
                      <h4 style={{color: "#222222"}}>Address</h4>
                        <label className='col-form-label required'>
                            Store Address
                        </label>
                        {/* <input type={"text"} name="store_address" onChange={(e)=> handleOnChange(e)} className="form-control" /> */}
                        <AddressAutoComplete inputValues={inputValues} setInputValues={setInputValues}
                        address={inputValues.store_address && inputValues.store_address} />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md">
                          <label className="col-form-label required">
                            Block / Apt / Office
                          </label>
                          <input type={"text"} value={inputValues.store_colony && inputValues.store_colony} name="store_colony" className="form-control" onChange={(e) => handleOnChange(e)} />
                        </div>
                        <div className="col-md">
                          <label className="col-form-label required">
                            Landmark
                          </label>
                          <input type={"text"} value={inputValues.store_landmark && inputValues.store_landmark} name="store_landmark" className="form-control" onChange={(e) => handleOnChange(e)} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-form-label required">Street</label>
                      <input type={"text"} name="store_flat_no" className="form-control" onChange={(e)=> handleOnChange(e)} />
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-md">
                          <label className="col-form-label required">
                            City
                          </label>
                          <input type={"text"} value={inputValues.store_city && inputValues.store_city} name="store_city" className="form-control" />
                        </div>
                        <div className="col-md">
                          <label className="col-form-label required">
                            State
                          </label>
                          <input type={"text"} value={inputValues.store_state && inputValues.store_state} name="store_state" className="form-control" />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-form-label required">Country</label>
                      <input type={"text"} name="store_country" value={inputValues.store_country && inputValues.store_country} className="form-control" />
                    </div>

                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <h4 style={{fontWeight:"bolder", color: "#222222"}}>Business Information</h4>
                    <div className='form-group'>
                          <label className='col-form-label required'>
                            Business Name
                          </label>
                          <input
                            name='store_name'
                            onChange={(e)=> handleOnChange(e)}
                            type='text'
                            className='form-control'
                            id='storename'
                            placeholder='Enter Business Name'
                          />
                    </div>
                    <div className='form-group'>
                          <label className='col-form-label required'>
                            Select What you are?
                          </label>
                          <ul>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='what_you_are'
                                  type='radio'
                                  className='mr-1'
                                  value={"1"}
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  Wholeseller
                                </label>
                              </div>
                            </li>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='what_you_are'
                                  type='radio'
                                  className='mr-1'
                                  value={"2"}
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  Retailer
                                </label>
                              </div>
                            </li>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='what_you_are'
                                  type='radio'
                                  value={"3"}
                                  className='mr-1'
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  Both
                                </label>
                              </div>
                            </li>
                          </ul>
                    </div>
                    <div className='form-group'>
                          <label className='col-form-label required'>
                            Where do you get Products from? 
                          </label>
                          <ReactSelect
                            value={selectedProductsFrom}
                            name="store_product_from"
                            options={getProductsOptions}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option
                            }}
                            onChange={(e)=> handleMultipleSelectProducts(e)}
                            allowSelectAll={true}
                          />
                          {/* <select className="form-control" name="store_product_from" multiple 
                        onChange={(e)=> handleOnChange(e)}>
                              <option disabled>{"Select"}</option>
                              <option value={"I manufacture theme"}>I manufacture theme</option>
                              <option value={"I resell product that I buy"}>I resell product that I buy</option>
                              <option value={"I import them"}>I import them</option>
                        </select> */}

                          {/* <ul>
                          <li className="w-100">
                              <div className="radio-option">
                                <input
                                  type='checkbox'
                                  name="store_product_from"
                                  className='mr-1'
                                  onChange={(e) => handleOnChange(e, 0)}
                                  value={"I manufacture theme"}
                                />
                                <label>
                                  I manufacture theme
                                </label>
                              </div>
                            </li>
                          <li className="w-100">
                              <div className="radio-option">
                                <input
                                  type='checkbox'
                                  className='mr-1'
                                  name="store_product_from"
                                  onClick={(e)=> handleOnChange(e)}
                                  value={"I resell product that I buy"}
                                />
                                <label>
                                  I resell product that I buy
                                </label>
                              </div>
                            </li>
                          <li className="w-100">
                              <div className="radio-option">
                                <input
                                  type='checkbox'
                                  className='mr-1'
                                  name="store_product_from"
                                  onClick={(e)=> handleOnChange(e)}
                                  value={"I import them"}
                                />
                                <label>
                                  I import them
                                </label>
                              </div>
                            </li>
                          </ul> */}
                    </div>

                    <div className='form-group'>
                          <label className='col-form-label required'>
                          what is your annual turnover ?
                          </label>
                          <ul>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='store_annual_turnover'
                                  type='radio'
                                  className='mr-1'
                                  value={"1"}
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  Less then 10 lacs
                                </label>
                              </div>
                            </li>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='store_annual_turnover'
                                  type='radio'
                                  className='mr-1'
                                  value={"2"}
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  10 lacs to 50 lacs
                                </label>
                              </div>
                            </li>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='store_annual_turnover'
                                  type='radio'
                                  className='mr-1'
                                  value={"3"}
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  50 lacs to 1 Cr
                                </label>
                              </div>
                            </li>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='store_annual_turnover'
                                  type='radio'
                                  className='mr-1'
                                  value={"4"}
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  More then 1 Cr
                                </label>
                              </div>
                            </li>
                          </ul>
                    </div>
                    <div className='form-group'>
                          <label className='col-form-label required'>
                            How many Products do you want to sell ?
                          </label>
                          <ul>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='store_product_sell'
                                  type='radio'
                                  className='mr-1'
                                  value={"1"}
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  0 - 5
                                </label>
                              </div>
                            </li>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='store_product_sell'
                                  type='radio'
                                  value={"2"}
                                  className='mr-1'
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  5 - 15
                                </label>
                              </div>
                            </li>
                            <li className="w-100">
                              <div className="radio-option">
                                <input
                                  name='store_product_sell'
                                  type='radio'
                                  value={"3"}
                                  className='mr-1'
                                  onClick={(e)=> handleOnChange(e)}
                                />
                                <label>
                                  More than 15
                                </label>
                              </div>
                            </li>
                          </ul>
                    </div>

                    <div className="outerBox borNone" style={{borderRadius: "4px"}}>
                      <div className="form-group">
                        <label className='col-form-label required'>
                                  GST Details
                        </label><br/>
                        <small>Is your sell in GST exempted category ?</small>
                        <div className="container">
                        <select className="form-control" name="store_is_gst" onChange={(e)=> handleOnChange(e)}>
                            <option selected disabled>{"select GST status "}</option>
                            <option value="1">I have GST Number</option>
                            <option value="0">I don't have GST Number</option>
                        </select>
                        {inputValues.store_is_gst && inputValues.store_is_gst ? 
                          <div className="pt-2">
                            <input
                                name='gst_no'
                                type='text'
                                className='form-control pt-2'
                                maxLength={15}
                                id='GST'
                                value={inputValues.gst_no && inputValues.gst_no.length > 0 ? inputValues.gst_no : ""}
                                placeholder='Enter GST Number'
                                onChange={(e) => handleOnChange(e)}
                              />
                          </div>
                          : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                        <label className='col-form-label required'>
                                Choose Categories you wish to sell
                        </label>
                        <ReactSelect
                          value={selectedCategoryOption}
                          name="store_category"
                          options={newCategoryOptions && newCategoryOptions}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option
                          }}
                          onChange={(e)=> handleMultipleSelectCategory(e)}
                          allowSelectAll={true}
                        />
                        {/* <select className="form-control" name="store_category" multiple 
                        onChange={(e)=> handleOnChange(e)}>
                              <option disabled>{"Select Categories"}</option>
                              <option value={"Cement"}>Cement</option>
                              <option value={"Bricks"}>Bricks</option>
                              <option value={"Sand"}>Sand</option>
                              <option value={"Electrical"}>Electrical</option>
                              <option value={"Plumbing"}>Plumbing</option>
                              <option value={"Wooden Products"}>Wooden Products</option>
                              <option value={"Paints"}>Paints</option>
                              <option value={"Paint Finishes"}>Paint Finishes</option>
                              <option value={"Doors & Windows"}>Doors & Windows</option>
                        </select> */}
                    </div>


                    {/* <div className="form-group">
                        <label className='col-form-label required'>
                                Upload Proofs
                        </label>
                        <input type="file" onChange={(e) => handleOnChange(e)} name="store_address_proof" className='form-control' multiple accept="image/*,.pdf" />
                    </div> */}
                    {/* <div className="form-group">
                        <label className='col-form-label required'>
                                Upload TAX Bill Details Proofs
                        </label>
                        <input type="file" name="billProof" onChange={(e) => handleOnChange(e)} className='form-control' multiple accept="image/*,.pdf" />
                    </div> */}


                </div>
                <div className="col-md-12 justify-content-between">
                  <h4 style={{fontWeight:"bolder", color: "#222222"}} className="p-2">Upload Proofs</h4>
                <div className="d-flex row respoCol">
                  <div className="col-md-3">
                    <div className="sellIdIn">
                      <div className="sellerRegPersonalImg">
                        <img src={inputValues.personal_pancard_preview ? inputValues.personal_pancard_preview : ""} className="img-fluid"  width={"100"} height={"60"} />
                      </div>
                      <div className="form-group">
                        <label className='col-form-label required'>
                          Pan Card
                        </label>
                        <small className="mb-3 font-weight-bolde" >Upload your Proofs in PDF, JPEG or PNG Format</small>
                        <input type="file" onChange={(e) => handleOnChange(e)} name="personal_pancard" className='form-control' accept="image/*,.pdf" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="sellIdIn">
                      <div className="sellerRegPersonalImg">
                        <img src={inputValues.store_gst_proof_preview ? inputValues.store_gst_proof_preview : ""} className="img-fluid"  width={"100"} height={"60"} />
                      </div>
                      <div className="form-group">
                        <label className='col-form-label required'>
                          Business Id Proof
                        </label>
                        <small className="mb-3 font-weight-bolde" >Upload your Proofs in PDF, JPEG or PNG Format</small>
                        <input type="file" onChange={(e) => handleOnChange(e)} name="store_gst_proof" className='form-control' accept="image/*,.pdf" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                  <div className="sellIdIn">
                    <div className="sellerRegPersonalImg">
                      <img src={inputValues.personal_signature_preview ? inputValues.personal_signature_preview : ""} className="img-fluid"  width={"100"} height={"60"} />
                    </div>
                    <div className="form-group">
                      <label className='col-form-label required'>
                        Upload Signature
                      </label>
                      <small className="mb-3 font-weight-bolde" >Upload your Proofs in PDF, JPEG or PNG Format</small>
                      <input type="file" onChange={(e) => handleOnChange(e)} name="personal_signature" className='form-control' accept="image/*,.pdf" />
                    </div>
                  </div>
                  </div>
                  <div className="col-md-3">
                    <div className="sellIdIn">
                      <div className="sellerRegPersonalImg">
                        <img src={inputValues.profile_image_preview ? inputValues.profile_image_preview : ""} className="img-fluid"  width={"100"} height={"60"} />
                      </div>
                      <div className="form-group">
                        <label className='col-form-label required'>
                          Logo
                        </label>
                        <small className="mb-3 font-weight-bolde" >Upload your Proofs in PDF, JPEG or PNG Format</small>
                        <input type="file" onChange={(e) => handleOnChange(e)} name="profile_image" className='form-control' accept="image/*,.pdf" />
                      </div>
                    </div>
                  </div>
                      </div>
                </div>
                <div className="col-md-12 justify-content-center align-items-center" style={{textAlign:"center"}}>
                    <div className="form-group">
                        <button className="btn btn-solid" onClick={(e)=> registerVendor(e)}>Register</button>
                    </div>
                </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({

})

export default withRouter(connect(mapStateToProps, {})(SellerRegister));
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import countryData from "../pages/countries.json";
import EditAddress from "../pages/address/EditAddress";
import { editAddress_api, setAsDefaultAddress_api } from "../../api/auth";
import Breadcrumb from "../common/breadcrumb";
import Swal from 'sweetalert2'


import {
  removeFromWishlist,
  applyCouponDiscount,
  decrementQty,
  incrementQty,
  removeFromCart,
  resetTheCart,
  setCartdata,
  setCartdatafull,
  // updateWithDeliverySLots,
} from "../../actions";
import { getProductCartTotal } from "../../services";
import { declareOrderType, checkAndRedirectLogin } from "../../utils/helper";
import { getAllAddress_api, getCartList_api } from "../../api/auth";
import { userPlaceOrder_api, userPayumoney_api } from "../../api/product";
import SelectBillingAddress from "./SelectBillingAddress";
import SummaryCheckout from "./SummaryCheckout";
import PaymentOptionAndGiftCard from "./PaymentOptionAndGiftCard";


class CheckOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment: "stripe",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      country: "",
      address: [],
      city: "",
      state: "",
      pincode: "",
      create_account: "",
      isShowBillingAddressComponent: true,
      loaderForAddress: false,
      delivery_address_id: 0,
      billing_address_id: 0,
      sameAsDeliveryAddress: false,
      isShowPaymentAndGiftCardComponent: false,
      loaderForPlaceOrder: false,
      gift_id: 0,
      gift_code: "",
      gift_amount: 0,
      payumoney: "",
      showBack: true,
      goBack: "",
      disable_button: false,
      loading: true
    };
    // this.validator = new SimpleReactValidator();
  }

  componentDidMount() {

    if (!checkAndRedirectLogin()) {
      toast.info("Please login to continue");
      this.props.history.push({
        pathname: "/login",
      });
    } else {
      if (this.props.cartItems.length === 0) {
        // toast.info("Cart is empty!!!");
        this.props.history.push({
          pathname: "/cart",
        });
      } else {
        this.setAddressList();
        // this.setCartDatas()
      }
    }
  }

  setCartData = async (add_id) => {
    try {
        this.setState({loaderForCartList: true});
        // get cart list
        let apiParams = {
            user_id: this.props.auth.userData.id,
           code: localStorage.getItem("code_set"),
            address_id: add_id
            // language_id: 1,
        };
        let apiData = await getCartList_api(apiParams);
        // console.log(apiData);
        if (apiData.success) {
            let data = JSON.parse(JSON.stringify(apiData.data));
            for (let index = 0; index < data.length; index++) {
                for (const x in data[index].product_option) {
                    data[index].product_option[x].selected_options = [];
                }
                data[index].delivery_slots = [];
            }
            // set the cart data
            this.props.setCartdata(data);
            this.props.applyCouponDiscount(apiData.total_coupon_price ? apiData.total_coupon_price : 0);
            this.props.setCartdatafull(JSON.parse(JSON.stringify(apiData)));
            this.setState({
              disable_button: false
             });
        } else {
            toast.error(apiData.message);
        }
        this.setState({loaderForCartList: false});
    } catch (error) {
        this.setState({loaderForCartList: false});
        console.log(error);
        toast.error(error.message);
    }
};



  setAddressList = async () => {
    try {
      // this.setCartDatas()
      this.setState({ loaderForAddress: true, loading: true });
      const address = await getAllAddress_api();
      console.log("address");
      localStorage.setItem("addressData", JSON.stringify(address.data))
      this.setState(
        {
          loaderForAddress: false,
          address: address.data,
          loading: false 
        },
        () => {
          if (this.state.address.length > 0) {
            let GetDefaultAddress = this.state.address.filter(
              (item) => item.is_default === 1
            );
            if (GetDefaultAddress.length > 0) {
              this.setState({
                delivery_address_id: GetDefaultAddress[0].id,
                // billing_address_id: localStorage.getItem("billing_address_id") || 0,
              });
              localStorage.setItem("delivery_address_id", GetDefaultAddress[0].id)
              // localStorage.setItem("billing_address_id", GetDefaultAddress[0].id)
            } else {
              this.setState({
                sameAsDeliveryAddress: false,
              });
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForAddress: false });
    }
  };

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  checkhandle = (value) => {
    this.setState({
      payment: value,
    });
  };

  StripeClick = () => {
    localStorage.removeItem("code_set");

    if (this.state.payment !== "COD" && this.state.payment !== "Payumoney") {
      /*if (this.validator.allValid()) {
        alert("You submitted the form and stuff!");
      }*/

      var handler = window.StripeCheckout.configure({
        key: "pk_test_mZ6HfljXBzO7479xfG4u3Hbl", // pk_test_glxk17KhP7poKIawsaSgKtsL
        locale: "auto",
        token: (token) => {
          this.placeOrder(token);
        },
      });

      handler.open({
        name: "",
        description: "Online Store",
        amount:
          (this.props.total +
            parseFloat(
              this.props.cart_full ? this.props.cart_full.total_ship_price : 0
            ) +
            this.declareTaxesOfProduct() -
            this.props.coupon_discount -
            this.state.gift_amount) *
          100,
        closed: function() {
          this.setState({ loaderForPlaceOrder: false });
          // Stripe relode issue
          // window.location.reload();
        }.bind(this),
      });
    }

    if (this.state.payment == "Payumoney") {
      this.placeOrderwithPayumoney(null);
    } else {
      // cod  payment
      this.placeOrder(null);
    }
  };

  placeOrderwithPayumoney = async (tokenData) => {
    if (this.props.auth.isLoggedIn) {
      try {
        this.setState({ loaderForPlaceOrder: true });

        var slotdata = [];
        this.props.cartItems.map((val, id) => {
          if (val.delivery_slots && val.delivery_slots.length > 0) {
            var slot_data = [];
            val.delivery_slots.map((value, key) => {
              slot_data.push({
                slot_no: key + 1,
                slot_qty: value.qty,
                slot_price: val.wholesale_price,
                slot_total:
                  parseFloat(val.wholesale_price) * parseInt(value.qty),
                slot_date: value.date,
                slot_tax: "0",
              });
            });
            slotdata.push({
              cart_id: val.cart_id,
              product_id: val.id,
              slot_data: [...slot_data],
            });
          }
        });

        let apiParams = {
          transaction_id: tokenData && tokenData.id ? tokenData.id : "",
          user_id: this.props.auth.userData.id,
          delivery_address_id: this.state.delivery_address_id,
          billing_address_id: this.state.billing_address_id,
          is_billing_address: 0,
          coupon_id:
            this.props && this.props.cart_full && this.props.cart_full.coupon_id
              ? this.props.cart_full.coupon_id
              : "",
          gift_id: this.state.gift_id,
          gift_amount: this.state.gift_amount,
          payment_id: this.state.payment === "COD" ? 1 : 2,
          total:
            this.props.total +
            this.declareTaxesOfProduct() -
            this.state.gift_amount -
            this.props.coupon_discount +
            parseFloat(
              this.props.cart_full ? this.props.cart_full.total_ship_price : 0
            ),
          total_sp: 0,
          gift_wrapping_id: 0,
          slots:
            slotdata && slotdata.length >= 1 ? JSON.stringify(slotdata) : "",
        };
        // console.log('apiParams',apiParams);
        // return false;
        let orderApiData = await userPayumoney_api(apiParams);
        if (orderApiData) {
          this.setState({ payumoney: orderApiData.data });
          this.setState({ loaderForPlaceOrder: false });
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message);
        this.setState({ loaderForPlaceOrder: false });
      }
    }
  };

  placeOrder = async (tokenData) => {
    if (this.props.auth.isLoggedIn) {
      try {
        this.setState({ loaderForPlaceOrder: true });

        var slotdata = [];
        this.props.cartItems.map((val, id) => {
          if (val.delivery_slots && val.delivery_slots.length > 0) {
            var slot_data = [];
            val.delivery_slots.map((value, key) => {
              slot_data.push({
                slot_no: key + 1,
                slot_qty: value.qty,
                slot_price: val.wholesale_price,
                slot_total:
                  parseFloat(val.wholesale_price) * parseInt(value.qty),
                slot_date: value.date,
                slot_tax: "0",
              });
            });
            slotdata.push({
              cart_id: val.cart_id,
              product_id: val.id,
              slot_data: [...slot_data],
            });
          }
        });

        let apiParams = {
          transaction_id: tokenData && tokenData.id ? tokenData.id : "",
          user_id: this.props.auth.userData.id,
          delivery_address_id: this.state.delivery_address_id,
          billing_address_id: this.state.billing_address_id,
          is_billing_address: 0,
          coupon_id:
            this.props && this.props.cart_full && this.props.cart_full.coupon_id
              ? this.props.cart_full.coupon_id
              : "",
          gift_id: this.state.gift_id,
          gift_amount: this.state.gift_amount,
          payment_id: this.state.payment === "COD" ? 1 : 2,
          total:
            this.props.total +
            this.declareTaxesOfProduct() -
            this.state.gift_amount -
            this.props.coupon_discount +
            parseFloat(
              this.props.cart_full ? this.props.cart_full.total_ship_price : 0
            ),
          total_sp: 0,
          gift_wrapping_id: 0,
          slots:
            slotdata && slotdata.length >= 1 ? JSON.stringify(slotdata) : "",
        };
        console.info(
          "Place Order Click",
          this.props.cartItems.map(
            (val) =>
              val.delivery_slots &&
              val.delivery_slots.map((slots) => JSON.stringify(slots))
          )
        );
        console.info("slotData", slotdata);
        // console.log('apiParams',apiParams);
        // return false;
        let orderApiData = await userPlaceOrder_api(apiParams);
        this.setState({ loaderForPlaceOrder: false });
        // console.log(orderApiData);
        if (orderApiData.success) {
          toast.success("Order placed successfully");
          this.props.history.push({
            pathname: "/order-success",
            state: {
              payment: tokenData,
              items: this.props.cartItems,
              orderTotal: this.props.total,
              symbol: this.props.symbol,
              address: this.state.address,
              delivery_address_id: this.state.delivery_address_id,
              billing_address_id: this.state.billing_address_id,
              coupon_discount: this.props.coupon_discount,
              order_data: orderApiData.orderdata,
            },
          });
        } else {
          toast.error(orderApiData.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message);
        this.setState({ loaderForPlaceOrder: false });
      }
    }
  };

  declareTaxesOfProduct = () => {
    const { cartItems } = this.props;
    var total = 0;
    var taxTypesSum;
    for (var i = 0; i < cartItems.length; i++) {
      taxTypesSum = 0;
      // if (declareOrderType(cartItems[i]) === "Retail") {
      //   if (cartItems[i].tax_price.length > 0) {
      //     for (var j = 0; j < cartItems[i].tax_price.length; j++) {
      //       if (cartItems[i].tax_price[j].tax_amount)
      //         taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
      //     }
      //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
      //   }
      // } else {
      //   if (cartItems[i].wholesale_tax_price.length > 0) {
      //     for (var k = 0; k < cartItems[i].wholesale_tax_price.length; k++) {
      //       if (cartItems[i].tax_price[k].tax_amount)
      //         taxTypesSum += parseInt(
      //           cartItems[i].wholesale_tax_price[k].tax_amount
      //         );
      //     }
      //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
      //   }
      // }
      if (cartItems[i].tax_price.length > 0) {
        for (var j = 0; j < cartItems[i].tax_price.length; j++) {
          if (cartItems[i].tax_price[j].tax_amount)
            taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
        }
        total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
      }
    }
    return total;
  };

  handleNextClickaddress = (e, item) => {
    this.setCartDatas(item);
  };

  handleNextClick = () => {
    if (this.state.delivery_address_id === 0) {
      return toast.info("Please select Delivery Address");
    }
    if(this.state.billing_address_id === 0) {


      Swal.fire({
        text: 'Billing Address is not selected,Are you sure to set your Billing Address as a Delivery Address ?',
        title: '',
        showCancelButton: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.setState({
            billing_address_id: this.state.delivery_address_id,
          });
          localStorage.setItem("billing_address_id", this.state.delivery_address_id)
          this.confirmNext()
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
  }else {
    this.confirmNext()
  }

   
  };

  confirmNext = () => {
 if (
      this.state.delivery_address_id !== 0 
    ) {
      this.setState((prevState) => ({
        isShowBillingAddressComponent: !prevState.isShowBillingAddressComponent,
        goBack: true,
      }));
      this.props.history.push({pathname: "/checkout-summary"})
    }
    if (this.state.delivery_address_id === 0) {
      toast.info("Please select Delivery Address");
    }
    // if (this.state.billing_address_id === 0) {
    //   toast.info("Please select Billing Address");
    // }
    else {
      // toast.info("Please select the address");
    }
  }

  handleBackClick = () => {
    if (!this.state.isShowBillingAddressComponent) {
      this.setState((prevState) => ({
        isShowBillingAddressComponent: !prevState.isShowBillingAddressComponent,
        isShowPaymentAndGiftCardComponent: false,
        goBack: false,
      }));
    }
    if (this.state.isShowPaymentAndGiftCardComponent) {
      this.setState((prevState) => ({
        isShowBillingAddressComponent: false,
        isShowPaymentAndGiftCardComponent: false,
        goBack: true,
      }));
    }
  };

  handleDeliveryAddressRadioClick =  (e, item) => {
    if (item) {
   
      localStorage.setItem("delivery_address_id", item.id)

      this.setState(
        {
          delivery_address_id: item.id,
          disable_button: true
        },
        () => {
          if (this.state.sameAsDeliveryAddress) {
            this.setState({
              billing_address_id: this.state.delivery_address_id,
            });
            localStorage.setItem("billing_address_id", this.state.delivery_address_id)
          }
        }
        );
        this.setCartData(item.id)
      // this.setState({ loaderForAddress:false });
    }
  };

  setStateFromCheckbox = (event) => {
    const { name, checked } = event.target;
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj, () => {
      if (checked) {
        this.setState({
          billing_address_id: this.state.delivery_address_id,
        });
        localStorage.setItem("billing_address_id", this.state.delivery_address_id)
      }
    });
  };

  handleBillingAddressRadioClick = (e, item) => {
    if (item) {
      this.setState({
        billing_address_id: item.id,
      });
      localStorage.setItem("billing_address_id", item.id)
    }
  };

  handlePaymentNextClick = () => {
    if (
      this.state.delivery_address_id !== 0 &&
      this.state.billing_address_id !== 0
    ) {
      this.setState((prevState) => ({
        isShowPaymentAndGiftCardComponent: !prevState.isShowPaymentAndGiftCardComponent,
      }));
    } else {
      // toast.info("Please select the address");
    }
  };

  addGiftcode = (id, code, amount) => {
    this.setState({
      gift_id: id,
      gift_code: code,
      gift_amount: amount,
    });
    localStorage.setItem("gift_id", id);
    localStorage.setItem("gift_code", code);
    localStorage.setItem("gift_amount", amount);
  };

  setCartDatas = async (item = 0, code) => {
    try {
      console.log("this.props.cart_full", this.props.cart_full);
      this.setState({ loaderForCartList: true });
      // get cart list

      let apiParams = {
        user_id: this.props.auth.userData.id,
        code: localStorage.getItem("code_set"),
        address_id: item,
      };

      // localStorage.removeItem('code_set')

      let apiData = await getCartList_api(apiParams);
      // console.log(apiData);
      if (apiData.success) {
        let data = JSON.parse(JSON.stringify(apiData.data));
        for (let index = 0; index < data.length; index++) {
          for (const x in data[index].product_option) {
            data[index].product_option[x].selected_options = [];
          }
          data[index].delivery_slots = [];
        }
        // set the cart data
        this.props.setCartdata(data);
        this.props.applyCouponDiscount(
          apiData.total_coupon_price ? apiData.total_coupon_price : 0
        );
        this.props.setCartdatafull(JSON.parse(JSON.stringify(apiData)));
      } else {
        toast.error(apiData.message);
      }
      this.setState({ loaderForCartList: false });
    } catch (error) {
      this.setState({ loaderForCartList: false });
      console.log(error);
      toast.error(error.message);
    }
  };

  editAddressApi = async (data) => {
    try {
      this.setState({ loaderForAddress: true });
      data.address_id = this.state.editAddress.id;
      const newAddressAdded = await editAddress_api(data);
      this.props.setAddressList();
      this.setState((prevState) => ({
        loaderForAddress: false,
        isShowEditAddressPage: false,
        editAddress: null,
      }));
      toast.success("Address has been updated successfully");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForAddress: false });
    }
  };

  showEditPage = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isShowEditAddressPage: !prevState.isShowEditAddressPage,
    }));
  };

  handleEditClick = (e, item) => {
    e.persist();
    this.setState(
      {
        editAddress: item,
      },
      () => {
        this.showEditPage(e);
      }
    );
  };

  render() {
    console.log(this.loaderForAddress,"loaderForAddress")
    const {
      cartItems,
      symbol,
      total,
      coupon_discount,
      total_ship_price,
      cart_full,
    } = this.props;
    const { payumoney, loaderForAddress } = this.state;
    const { isLoggedIn } = this.props.auth;
    const {
      isShowBillingAddressComponent,
      isShowPaymentAndGiftCardComponent,
      goBack,
    } = this.state;
    let address_type;

    
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>CheckOut </title>
          <meta
            name='description'
            content=' – It is designed to go well with multi-purpose websites.  Bootstrap 4 Template will help you run multiple businesses.'
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb
          handleBackClick={this.handleBackClick}
          goBack={goBack}
          showBack={this.state.showBack}
          title={"Checkout - Select Billing"}
        />

        {isShowBillingAddressComponent && !isShowPaymentAndGiftCardComponent && (
          // <SelectBillingAddress
          //   handleNextClick={this.handleNextClick}
          //   handleNextClickaddress={this.handleNextClickaddress}
          //   address={this.state.address}
          //   delivery_address_id={this.state.delivery_address_id}
          //   billing_address_id={this.state.billing_address_id}
          //   sameAsDeliveryAddress={this.state.sameAsDeliveryAddress}
          //   setStateFromCheckbox={this.setStateFromCheckbox}
          //   handleDeliveryAddressRadioClick={
          //     this.handleDeliveryAddressRadioClick
          //   }
          //   handleBillingAddressRadioClick={
          //     this.handleBillingAddressRadioClick
          //   }
          //   setAddressList={this.setAddressList}
          //   {...this.props}
          // />
          <section className='section-b-space address-list-checkout'>
            {/* <div className="container padding-cls"> */}
            <div className='container-fluid'>
              <div className='row mx-md-5 mx-sm-1'>
                <div className='col-sm-12 '>
                  <div className='checkout-page'>
                    <div className='checkout-form'>
                      {this.state.address && this.state.address.length > 0 && (
                        <form>
                          <div className='dashboard checkout row '>
                            {!this.state.isShowEditAddressPage ? (
                              <>
                                <div className='col-lg-7 col-sm-12 col-xs-12'>
                                  <div className='box'>
                                    <div className='box-title '>
                                      <h3
                                        className='set_color_dark_1 font-size-text-20'
                                        style={{
                                          fontWeight: "700",
                                          fontSize: "24PX",
                                        }}
                                      >
                                        DELIVERY ADDRESS
                                      </h3>
                                      <Link
                                        className='font-weight-bold'
                                        to={`${process.env.PUBLIC_URL}/dashboard/address?is_address=1`}
                                      >
                                        {/* <i className="fa fa-plus" />  */}
                                        ADD NEW ADDRESS
                                      </Link>
                                    </div>
                                  </div>
                                  <div className='row check-out border-mobile-address'>
                                    <div className='col-md-12 '>
                                      {this.state.address.map((item, index) => {
                                        address_type = "Home";
                                        if (item.address_type === 2) {
                                          address_type = "Office";
                                        } else if (item.address_type === 3) {
                                          address_type = "Site";
                                        }
                                        return (
                                          <div
                                            className='row mt-3 border-mobile-address_bottom'
                                            key={index}
                                          >
                                            {/*<div className="col-1">
                                          <input type="radio" name="address" />

                                          <input
                                              type="radio"
                                              name="address"
                                              defaultChecked={item.is_default == 1? true: false}
                                              onClick={(e) =>
                                                  this.props.handleDeliveryAddressRadioClick(
                                                      e,
                                                      item
                                                  )
                                              }
                                          />
                                        </div>*/}
                                            <div className='col-9 address-text-set-n '>
                                              <input
                                                type='radio'
                                                name='address'
                                                className='mx-1'
                                                defaultChecked={
                                                  item.is_default == 1
                                                    ? true
                                                    : false
                                                }
                                                onClick={(e) =>
                                                  this.handleDeliveryAddressRadioClick(
                                                    e,
                                                    item
                                                  )
                                                }
                                              />
                                              <strong className='set_color_dark_1'>
                                                {item.user_name}{" "}
                                                <span className='badge badge-primary  badge-custome'>
                                                  {address_type}
                                                </span>
                                              </strong>
                                              <div className=''>
                                                {" "}
                                                {`${item.flat_no != null  ? item.flat_no + ", " : ""}` +item.address_1}{" "}
                                              </div>
                                              <strong>Landmark:</strong>{" "}
                                              <span>{item.landmark}</span>
                                              <br />
                                              <strong>City:</strong>{" "}
                                              <span>{item.city_name}</span>
                                              <br />
                                              <strong>State:</strong>{" "}
                                              <span>{item.state_id}</span>
                                              <br />
                                              <strong>Country:</strong>{" "}
                                              <span>{item.country_id}</span>
                                              <br />
                                              <div className=''>
                                                <b>Phone Number: </b>{" "}
                                                {item.mobile_no}
                                              </div>
                                              <div className=''>
                                                <b>Supervisor Name: </b>{" "}
                                                {item.supervision_name}
                                              </div>
                                              <div className=''>
                                                <b>Supervisor Number: </b>{" "}
                                                {item.supervision_mobile_no}
                                              </div>
                                            </div>
                                            <div className='col'>
                                              <div className='box pull-right'>
                                                <a
                                                  className='font-weight-bold theme_text_set'
                                                  href='javascript:void();'
                                                  onClick={(e) =>
                                                    this.handleEditClick(
                                                      e,
                                                      item
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md-5 mb-md-4 mt-sm-1 mb-sm-1'>
                                      <input
                                        type='checkbox'
                                        name='sameAsDeliveryAddress'
                                        id='account-option'
                                        checked={
                                          this.state.sameAsDeliveryAddress
                                        }
                                        onChange={this.setStateFromCheckbox}
                                      />
                                      &ensp;{" "}
                                      <label
                                        className='font-weight-bold'
                                        htmlFor='account-option'
                                        style={{ color: "#047bd5" }}
                                      >
                                        Same as Delivery Address  
                                      </label>
                                    </div>
                                  </div>

                                  {this.state.sameAsDeliveryAddress ===
                                    false && (
                                    <>
                                      <div className='box'>
                                        <div className='box-title'>
                                          <h3
                                            className='set_color_dark_1 font-size-text-20'
                                            style={{
                                              fontWeight: "700",
                                            }}
                                          >
                                            BILLING ADDRESS
                                          </h3>
                                          <Link
                                            to={`${process.env.PUBLIC_URL}/dashboard/address`}
                                            className='font-weight-bold theme_text_set'
                                          >
                                            <i className='fa fa-plus' /> ADD
                                            ADDRESS
                                          </Link>
                                        </div>
                                      </div>
                                      <div className='row check-out border-mobile-address'>
                                        <div className='col-md-12 '>
                                          {this.state.address.map(
                                            (item, index) => {
                                              address_type = "Home";
                                              if (item.address_type === 2) {
                                                address_type = "Office";
                                              } else if (
                                                item.address_type === 3
                                              ) {
                                                address_type = "Site";
                                              }
                                              return (
                                                <div
                                                  className='row mt-3 border-mobile-address_bottom'
                                                  key={index}
                                                >
                                                  <div className='col-9 address-text-set-n '>
                                                    <input
                                                      className='mx-1'
                                                      type='radio'
                                                      name='billing'
                                                      value={
                                                        this.state
                                                          .billing_address_id
                                                      }
                                                      checked={
                                                        item.id ==
                                                        this.state
                                                          .billing_address_id
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={(e) =>
                                                        this.handleBillingAddressRadioClick(
                                                          e,
                                                          item
                                                        )
                                                      }
                                                    />
                                                    <strong className='set_color_dark_1'>
                                                      {item.user_name}{" "}
                                                      <span className='badge badge-primary  badge-custome'>
                                                        {address_type}
                                                      </span>
                                                    </strong>{" "}
                                                    <br />
                                                    { item.company_name ?  <p><span> <strong>Company Name: </strong>{item.company_name}</span></p>: 
                                                    <p><span><strong>Company Name: </strong>
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/dashboard/address`}
                                                        className='font-weight-bold set_color_dark_2'
                                                      >
                                                        Add Company Name
                                                      </Link>
                                                      </span></p>
                                                     } 
                                                    { item.gst_number ?  <p><span><strong>GSTIN No.: </strong>{item.gst_number}</span></p>: 
                                                    <p><span><strong>GSTIN No.: </strong>
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/dashboard/address`}
                                                        className='font-weight-bold set_color_dark_2'
                                                      >
                                                        Add GST Number
                                                      </Link>
                                                      </span></p>
                                                     } 
                                                    {`${item.flat_no != null  ? item.flat_no + ", " : ""}` +item.address_1} <br />
                                                    <strong>
                                                      Landmark:
                                                    </strong>{" "}
                                                    <span>{item.landmark}</span>
                                                    <br />
                                                    <strong>City:</strong>{" "}
                                                    <span>
                                                      {item.city_name}
                                                    </span>
                                                    <br />
                                                    <strong>State:</strong>{" "}
                                                    <span>{item.state_id}</span>
                                                    <br />
                                                    <strong>
                                                      Country:
                                                    </strong>{" "}
                                                    <span>
                                                      {item.country_id}
                                                    </span>
                                                    <br />
                                                    <strong>
                                                      Phone Number:
                                                    </strong>{" "}
                                                    <span>
                                                      {item.mobile_no}
                                                    </span>{" "}
                                                    <br />
                                                    <strong>
                                                      Supervisor Name:
                                                    </strong>{" "}
                                                    <span>
                                                      {item.supervision_name}
                                                    </span>{" "}
                                                    <br />
                                                    <strong>
                                                      Supervisor Number:
                                                    </strong>{" "}
                                                    <span>
                                                      {
                                                        item.supervision_mobile_no
                                                      }
                                                    </span>
                                                  </div>
                                                  <div className='col'>
                                                    <div className='box pull-right'>
                                                      <Link
                                                        to={`${process.env.PUBLIC_URL}/dashboard/address`}
                                                        className='font-weight-bold set_color_dark_2'
                                                      >
                                                        Edit
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className='col-lg-5 col-sm-12 col-xs-12'>
                                  {/* <div className="checkout-details"> */}
                                  <div
                                    style={{
                                      // paddingLeft: "40px",
                                      paddingTop: "15px",
                                    }}
                                  >
                                    <div
                                      className='p-2'
                                      style={{ border: "1px solid #ced4da" }}
                                    >
                                      <h4 className='font-weight-bold my-2 set_color_dark_1'>
                                        PAYMENT SUMMARY 
                                      </h4>
                                      <table
                                        className='payment_summary_table'
                                        style={{
                                          // tableLayout: "fixed",
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <th className='py-2'>Subtotal</th>
                                            <td
                                              className='py-2'
                                              style={{ textAlign: "right" }}
                                            >
                                              {symbol}{" "}
                                              {cart_full && cart_full.total
                                                ? parseFloat(
                                                    cart_full.total
                                                  ).toFixed(2)
                                                : 0}
                                            </td>
                                          </tr>
                                          {/*  <tr>
                                <th  className="py-2">Grand total excl. taxes</th>
                                <td  className="py-2"  style={{textAlign: "right"}}>
                                  {symbol} {(cart_full&&cart_full.total)?(cart_full.total):0}
                                </td>
                              </tr>*/}
                                          {/*<tr>
                                <th  className="py-2" >Taxes</th>
                                <td  className="py-2"  style={{textAlign: "right"}}>
                                  {symbol} {(cart_full&&cart_full.total)?cart_full.total_tax_price:0}
                                </td>
                              </tr>*/}
                                          {cart_full &&
                                          cart_full.total_coupon_price &&
                                          cart_full.total_coupon_price >= 1 ? (
                                            <tr>
                                              <th className='py-2'>
                                                Coupon Discount
                                              </th>
                                              <td
                                                className='py-2 text-danger'
                                                style={{ textAlign: "right" }}
                                              >
                                                {symbol}{" "}
                                                {cart_full &&
                                                cart_full.total_coupon_price? parseFloat(cart_full.total_coupon_price).toFixed(2): 0}
                                              </td>
                                            </tr>
                                          ) : (
                                            ""
                                          )}
                                          <tr>
                                            <th className='py-2'>Shipping</th>
                                            <td
                                              className='py-2 text-danger'
                                              style={{ textAlign: "right" }}
                                            >
                                              {symbol}{" "}
                                              {cart_full &&
                                              cart_full.total_ship_price
                                                ? parseFloat(
                                                    cart_full.total_ship_price
                                                  ).toFixed(2)
                                                : 0}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th className='py-2 w-70 set_color_dark_1'>
                                              Grand Total
                                            </th>
                                            <td
                                              className='py-2 w-30 theme_text_ligblue'
                                              style={{ textAlign: "right" }}
                                            >
                                              {symbol}{" "}
                                              {cart_full && cart_full.total
                                                ? (
                                                    parseFloat(
                                                      cart_full.total_ship_price
                                                    ) +
                                                    parseFloat(
                                                      cart_full.total
                                                    ) -
                                                    parseFloat(
                                                      cart_full.total_coupon_price
                                                    )
                                                  ).toFixed(2)
                                                : 0}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div className=' nextBtn'>
                                        <button
                                          type='button'
                                          className='btn btn-solid '
                                          disabled={this.state.disable_button}
                                          // onClick={() => this.StripeClick()}
                                          onClick={() =>
                                            this.handleNextClick()
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          NEXT
                                        </button>
                                      </div>
                                    </div>

                                    <div className='payment-box mt-3'>
                                      {/*<Link
                                    to={`${process.env.PUBLIC_URL}/cart`}
                                    className="btn-solid btn"
                                >
                                  BACK
                                </Link>*/}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {isLoggedIn && this.state.isShowEditAddressPage && (
                              <EditAddress
                                countryData={countryData}
                                editAddress={this.state.editAddress}
                                showEditPage={this.showEditPage}
                                editAddressApi={this.editAddressApi}
                                loaderForAddress={this.state.loaderForAddress}
                              />
                            )}
                          </div>
                        </form>
                      )}

                      {/* if no address added */}
                      {this.state.address.length == 0 && (
                        <div className='dashboard checkout row border-mobile-address'>
                          <div className='col-sm-12 col-xs-12'>
                            <div className='box'>
                              <div className='box-title'>
                                <h3
                                  className='set_color_dark_1'
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "24PX",
                                  }}
                                >
                                  Delivery Address
                                </h3>
                              </div>
                            </div>
                            <div className='row check-out'>
                              <div className='col text-center'>
                                <p className='p-5'>
                                  No Delivery address added, please add new
                                  address to proceed further
                                </p>

                                <Link
                                  className=' text-center btn btn-danger'
                                  to={`${process.env.PUBLIC_URL}/dashboard/address?is_address=1`}
                                >
                                  Add Address
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* {!isShowBillingAddressComponent &&
          !isShowPaymentAndGiftCardComponent && (
            <SummaryCheckout
              handleNextClick={this.handleNextClick}
              handlePaymentNextClick={this.handlePaymentNextClick}
              StripeClick={this.StripeClick}
              delivery_address_id={this.state.delivery_address_id}
              billing_address_id={this.state.billing_address_id}
              address={this.state.address}
              {...this.props}
            />
          )} */}

        {/* {isShowPaymentAndGiftCardComponent && (
          <PaymentOptionAndGiftCard
            handleNextClick={this.handleNextClick}
            handlePaymentNextClick={this.handlePaymentNextClick}
            StripeClick={this.StripeClick}
            checkhandle={this.checkhandle}
            payumoney={payumoney}
            delivery_address_id={this.state.delivery_address_id}
            billing_address_id={this.state.billing_address_id}
            address={this.state.address}
            loaderForPlaceOrder={this.state.loaderForPlaceOrder}
            addGiftcode={this.addGiftcode}
            {...this.props}
          />
        )} */}

        {/* <div
          style={{
            paddingTop: "250px",
            paddingBottom: "250px",
          }}
        /> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  cart_full: state.cartList.cart_full,
  symbol: state.data.symbol,
  total: getProductCartTotal(state.cartList.cart),
  coupon_discount: state.cartList.coupon_discount,
  auth: state.auth,
});


export default connect(mapStateToProps, {
  removeFromWishlist,
  // updateWithDeliverySLots,
  applyCouponDiscount,
  setCartdata,
  setCartdatafull,
})(CheckOut);
